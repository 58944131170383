import { Button, Card, Flex, Form, Input, Tabs, message } from 'antd'
import React, { useState } from 'react'
import axios from 'axios'
import { NavLink } from 'react-router-dom';
const ChangeProfile = ({ isLocal, myUser, setMyUser }) => {
    const [changePasswordForm] = Form.useForm()
    const [changeEmailForm] = Form.useForm()
    const [messageApi, contextHolder] = message.useMessage()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [emailIsSubmitting, setEmailIsSubmitting] = useState(false)

    const defaultEmailForm = {
        email: myUser?.emailAddress
    }

    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
            duration: 4
        })
    }

    const error = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
            duration: 4
        })
    }

    const handleChangePassword = (props) => {
        setIsSubmitting(true)
        const data = { ...props, accountID: myUser?.accountID }
        console.log(data)
        axios({
            method: 'post',
            url: (() => {
                if (isLocal === true) {
                    return 'http://localhost:8000/change-password'
                } else {
                    return 'api/change-password'
                }
            })(),
            data: data,
            withCredentials: true
        })
            .then((response) => {
                console.log(response.data.message)
                success(response.data.message)
                changePasswordForm.resetFields()
            })
            .catch((err) => {
                console.log(err.response)
                error(err.response.data.message)
            })
            .finally(() => {
                setIsSubmitting(false)
            })
    }

    const handleChangeEmail = (props) => {
        console.log(props)
        setEmailIsSubmitting(true)
        axios({
            method: 'post',
            withCredentials: true,
            url: (() => {
                if (isLocal === true) {
                    return 'http://localhost:8000/change-email'
                } else {
                    return 'api/change-email'
                }
            })(),
            data: { ...props, accountID: myUser?.accountID }
        })
            .then((response) => {
                console.log(response.data.message)
                success(response.data.message)
                setMyUser({ ...myUser, emailAddress: response.data.newEmail })
            })
            .catch((err) => {
                error(err.response.data.message)
            })
            .finally(() => {
                setEmailIsSubmitting(false)
            })
    }
    return (
        <>
            <NavLink to = "/home"><Button>Back</Button></NavLink>
            {contextHolder}
            <Flex vertical justify='center'>
                <Flex justify='center'>
                    <Card title='Edit Account' style={{ width: '700px', margin: '90px 0 24px' }}>
                        <Tabs
                            defaultActiveKey={1}
                            items={[
                                {
                                    key: 1,
                                    label: 'Change Password',
                                    children:
                                        <div>
                                            <Form
                                                form={changePasswordForm}
                                                labelCol={{ span: 8 }}
                                                onFinish={handleChangePassword}>

                                                <Form.Item name={'currentPassword'}
                                                    label='Current Password'
                                                    rules={[{
                                                        required: true,
                                                        message: 'Please enter your current password'
                                                    }]}>
                                                    <Input.Password />
                                                </Form.Item>

                                                <Form.Item name={'newPassword'}
                                                    label='New Password'
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter your new password.'
                                                        },
                                                        {
                                                            min: 5,
                                                            message: 'Your password must contain atleast 5 characters'
                                                        }
                                                    ]}>
                                                    <Input.Password />
                                                </Form.Item>

                                                <Form.Item name={'confirmPassword'}
                                                    label='Confirm Password'
                                                    hasFeedback
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please confirm your password!',
                                                        },
                                                        {
                                                            min: 5,
                                                            message: 'Your password must contain atleast 5 characters'
                                                        },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (!value || getFieldValue('newPassword') === value) {
                                                                    return Promise.resolve();
                                                                }
                                                                return Promise.reject(new Error('The new password that you entered do not match!'));
                                                            },
                                                        }),
                                                    ]}>
                                                    <Input.Password />
                                                </Form.Item>
                                                <Button loading={isSubmitting} htmlType='submit' block type='primary'>Submit</Button>
                                            </Form>
                                        </div>
                                },
                                {
                                    key: 2,
                                    label: 'Change Email',
                                    children: <>
                                        <Form
                                            initialValues={defaultEmailForm}
                                            form={changeEmailForm}
                                            onFinish={handleChangeEmail}>
                                            <Form.Item
                                                name='email'
                                                label='Email'
                                                rules={[
                                                    { required: true, message: 'Please enter a valid email address!' },
                                                    { type: 'email', message: 'Please enter a valid email address!' }
                                                ]}>
                                                <Input />
                                            </Form.Item>
                                            <Button type='primary' htmlType='submit' block loading={emailIsSubmitting}>Submit</Button>
                                        </Form>
                                    </>
                                }
                            ]} />
                    </Card>
                </Flex>
            </Flex>
        </>
    )
}

export default ChangeProfile