import { Select, Menu, Avatar, Card, Divider, Flex, Row, Space, Typography, Layout, message, Modal, Spin, ConfigProvider } from 'antd'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { BugOutlined, HomeOutlined, LogoutOutlined, LaptopOutlined, NotificationOutlined, UserOutlined, LineOutlined, CreditCardOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import SubscriptionPlan from "../components/Plan"
import { EditProfile } from '../components/editProfile/EditProfile'
import { LaserProfileModal } from '../components/LaserProfile/LaserProfileModal'
import { NavLink, useLocation } from 'react-router-dom';
import { CalculatorNavigationHomePage } from '../components/CalculatorNavigationHomePage'
import FetchCalculations from '../components/FetchCalculations'
import FetchLaserProfile from '../components/MyLaserProfileFetch'
import RTLSS from '../assets/Logo/rtlss logo.png'
import { ReactComponent as Bars } from '../assets/Bars.svg'
import Loader from '../assets/Loader'
import Cookies from 'js-cookie'

const { Sider, Content, Footer } = Layout
const { Text } = Typography
//
//   props = {
//     myCommits: {
//       error: null,
//       message: null,
//       data: {
//         git: null,
//         monday: null
//       }
//     }
//   }

const Home = ({ isLocal, myCommits, gitIsFetching, myUser, setMyUser }) => {
  let updateDataSource = null
  let dataSource = null
  let year = new Date().getFullYear()
  const git = myCommits?.data?.git
  const monday = myCommits?.data?.monday?.data?.boards[0]?.groups[0]?.items // <-- this is an array of objects
  const mondayUsers = myCommits?.data?.mondayUsers?.data?.users
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [laserProfileVisibility, setLaserProfileVisibility] = useState(false)
  const [deleteIsLoading, setDeleteIsLoading] = useState(false)
  const [messageApi, contextHolder] = message.useMessage();
  const { myLaserProfile, laserProfileIsFetching } = FetchLaserProfile(myUser, isLocal)
  const [sampleModalVisible, setSampleModalVisible] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  let sent2 = searchParams.get('defKey')
  let sent = searchParams.get('handleClick') 
  const[defKey, setDefKey] = useState( sent2 !== null ? sent2 : "1" )
  const [handleClick, sethandleClick] = useState(sent !== null ? sent : "home");
  const [spinning, setSpinning] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const { numberOfCalcs, isDisabled, isSubscribed, isFreeTrial, freeTrialActivated, isPreAuthorized } = FetchCalculations(isLocal, myUser)
  const [userLocation, setUserLocation] = useState(null)

  console.log("laser prof",myLaserProfile)
  useEffect(() => {
    if (Cookies.get('RTLSS_Loc') !== undefined) {
      setUserLocation(JSON.parse(Cookies.get('RTLSS_Loc')))
      console.log("check",JSON.parse(Cookies.get('RTLSS_Loc')))
    } else {
      console.log("undef")
    }
  
  }, [])
  useEffect(() => {
    if (numberOfCalcs !== null) {
      setSpinning(false);
    } else {
      setSpinning(true);
    }
  }, [numberOfCalcs]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleOk = () => {
    messageApi.open({

      type: 'success',
      content: 'This is a prompt message for success, and it will disappear in 3 seconds',
      duration: 3,

    });
    setIsModalOpen(false);
  };

  const handleDeleteProfile = (props) => {
    setDeleteIsLoading(true)
    let laserIDToDelete = props.currentTarget.value
    axios({
      method: 'post',
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/delete-laser'
        } else {
          return 'api/delete-laser'
        }
      })(),
      data: { laserIDToDelete: laserIDToDelete, accountID: myUser.accountID }
    })
      .then((response) => {
        success(response.data.message)
        setMyUser({ ...myUser, laserProfile: response.data.data })
      })
      .catch((err) => {
        isError(err.data.message)
      })
      .finally(() => {
        setDeleteIsLoading(false)
      })
  }

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
      duration: 4
    })  
  }

  const isError = (message) => {
    messageApi.open({
      type: 'error',
      conntent: message,
      duration: 4
    })
  }

  if (monday === undefined || mondayUsers === undefined) {
    updateDataSource = null
  } else {
    // Clean the data first.
    const cleanMondayData = monday.filter(({ name, column_values }) => column_values[1].text === 'Backlogs' || column_values[1].text === 'Working on it')
    console.log(`Clean Monday Data: `, cleanMondayData)

    if (cleanMondayData.length !== 0) {
      console.log(`Monday Users: `, mondayUsers)
      updateDataSource = cleanMondayData.map(({ name: desc, column_values }) => {
        return (
          {
            dev: (() => {
              if (column_values[0].value === null) {
                return (
                  <Flex justify='start' align='center'>
                    N/A
                  </Flex>
                )
              } else {
                let myDev = mondayUsers.find(({ id }) => id === JSON.parse(column_values[0].value).personsAndTeams[0].id)
                const { photo_thumb, name } = myDev
                return (
                  <Flex justify='start' align='center' style={{ height: '100%' }}>
                    <Space>
                      <Avatar src={photo_thumb} icon={<UserOutlined />} />
                      {name}
                    </Space>
                  </Flex>
                )
              }
            })(),
            desc: desc,
            timeline: (() => {
              if (column_values[2].value === null) {
                return null
              } else {
                return `${dayjs(JSON.parse(column_values[2].value).from).format('MMM DD')} - ${dayjs(JSON.parse(column_values[2].value).to).format('MMM DD')}`
              }
            })()
          }
        )
      })
    } else {
      cleanMondayData = null
    }
  }

  if (git === undefined) {
    dataSource = null
  } else {
    dataSource = git.map(({ author: { avatar_url, login }, commit: { message, author: { date } } }, index) => {
      return (
        {
          key: index + 1,
          login: (
            <Flex align='center'>
              <Space>
                <Avatar src={avatar_url} icon={<UserOutlined />} />
                {login}
              </Space>
            </Flex>
          ),
          description: message,
          date: dayjs(date).format('MMM DD YYYY')
        }
      )
    })
  }
  const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
    const key = String(index + 1);
    return {
      key: `sub${key}`,
      icon: React.createElement(icon),
      label: `subnav ${key}`,
      children: new Array(4).fill(null).map((_, j) => {
        const subKey = index * 4 + j + 1;
        return {
          key: subKey,
          label: `option${subKey}`,
        };
      }),
    };
  });
 
  return (
    <>
      {contextHolder}

      {/*Side Menu  */}
      <Modal
        open={sampleModalVisible}
        onCancel={() => { setSampleModalVisible(false) }}
        width={800}
      >
      {/* <LaserClassResultsV2 /> */}
      </Modal>
      <Spin spinning={spinning} size='large' indicator={<Bars style={{ fontSize: "200px", position:"absolute", left:"50%", right:"50%", top:"60%",transform: "translate(-41%, -50%)" }} />}>
   
        <Layout hasSider style={{
          minHeight: '100vh'
        }}>
          <Sider
            theme='light'
            breakpoint='lg'
            style={{
              position: 'sticky',
              left: 0,
              top: 78,
              bottom: 0,
            }}>

            {/* Side Menu Items*/}
            <Menu
              collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}
              theme='light'
              mode="inline"
              defaultSelectedKeys={defKey}
              defaultOpenKeys={defKey}
              style={{
                height: '100%',
                borderRight: "1px solid #F0F0F0",
                marginTop: "60px",
                color: "#1d1f8c"
              }}
            >
              <Menu.Item key="1" icon={<HomeOutlined />} onClick={() => sethandleClick("home")}>
                Home
              </Menu.Item>
              <Menu.Item key="2" icon={<UserOutlined />} onClick={() => sethandleClick("profile")}>
                Profile
              </Menu.Item>
              <Menu.Item key="3" icon={<LineOutlined />} onClick={() => sethandleClick("laserProfile")}>
                Saved Lasers
              </Menu.Item>
              <Menu.Item key="4" icon={<CreditCardOutlined />} onClick={() => sethandleClick("billing")}>
                Buy Calculations
              </Menu.Item>
              <Divider />
              <Menu.Item Key="5" icon={<BugOutlined />} style={{ color: 'red' }}>
                <NavLink to="/report" style={{ textDecoration: 'none' }}>
                  Report a Bug
                </NavLink>
              </Menu.Item>
  
              <Menu.Item style={{ color: "#1d1f8c"}}>
                <ConfigProvider
                  theme={{
                    components: {
                      Select: {
                        optionSelectedColor: "#1d1f8c",
                        colorText: "#1d1f8c"
                      },
                    },
                  }}
                >
                  <Select
                          defaultValue="iec"
                          style={{
                            textDecoration: "none",
                            width: 150
                          }}
                          options={[
                            {
                              value: "iec",
                              label: "IEC 60825-1 2014",
                            },
                            {
                              value: "ansi",
                              label: "ANSI",
                              disabled: true
                            },
                          ]}
                  />
                </ConfigProvider>
              </Menu.Item>
              <Divider />
              <Menu.Item key="6" icon={<LogoutOutlined />}>
                <NavLink to="/logout" style={{ textDecoration: 'none' }}>
                  Logout
                </NavLink>
              </Menu.Item>
            </Menu>
          </Sider>

        
          <Layout style={{ backgroundColor: "white" }}>
            {/*Home Page Content */}
            <Content style={{ marginTop: "50px", overflow: 'initial' }}>
              {handleClick === "home" ? (
                <>
                  <Row justify='center' align='middle' style={{marginBottom: "30px"}}>
                    {/* <img src={RTLSS} width={200} style={{ margin: '30px 40px 0 50px' }} /> */}
                    <h1 style={{ margin: '30px 0px 0 0px', color: '#1d1f8c', textAlign:"center" }} >Your Safety Starts Here. Pick a Calculator and Start Calculating!</h1>
                  </Row>

                  {/** Menu forthe calculators */}
                  <Row justify='start' align='middle'>
                    <CalculatorNavigationHomePage />
                  </Row>

                  {/* Dev Logs */}
                  {/* <Row justify='center' style={{ padding: '24px' }} gutter={16}> */}
                  {/* <Col lg={12} md={24}>
                  <Flex vertical='true' wrap="wrap">
                    <Flex justify='center'  wrap="wrap">
                      <Table
                        title={() => {
                          return (
                            <Flex justify='center'  wrap="wrap">
                              <strong>Dev Logs</strong>
                            </Flex>
                          )
                        }}
                        loading={(() => {
                          if (gitIsFetching) {
                            return true
                          } else {
                            return false
                          }
                        })()}
                        pagination={{ position: ['bottomCenter'], pageSize: 5 }}
                        dataSource={(() => {
                          if (git !== null) {
                            return dataSource
                          } else {
                            return null
                          }
                        })()}
                        columns={[
                          { title: 'Author', dataIndex: 'login', key: 'login' },
                          { title: 'Description', dataIndex: 'description', key: 'descrption' },
                          { title: 'Date', dataIndex: 'date', key: 'date' }
                        ]} />
                    </Flex>
                  </Flex>
                </Col> */}

                  {/* Upcoming Updates */}
                  {/* <Col lg={12} md={24}>
                  <Flex justify='center' vertical>
                    <Table
                      title={() => (
                        <Flex justify='center'><strong>Upcoming Updates</strong></Flex>
                      )}
                      loading={(() => {
                        if (gitIsFetching) {
                          return true
                        } else {
                          return false
                        }
                      })()}
                      pagination={{ position: ['bottomCenter'], pageSize: 5 }}
                      dataSource={(() => {
                        if (monday === null || monday === undefined) {
                          return null
                        } else {
                          return updateDataSource
                        }
                      })()}
                      columns={[
                        { title: 'Developer', dataIndex: 'dev', key: 'dev' },
                        { title: 'Update Description', dataIndex: 'desc', key: 'desc' },
                        { title: 'Timeline', dataIndex: 'timeline', key: 'timeline' }
                      ]}
                    />
                  </Flex>
                </Col> */}
                  {/* </Row> */}


                  {/* <Divider /> */}
                  {/* </Col>
              </Row> */}



                  {/* <Divider /> */}

                  {/* <Flex justify='center'>
                <div style={{ width: '500px', marginTop: "5%" }}>
                  <Typography.Title level={3}>Disclaimer</Typography.Title>
                  <Text>While every effort has been made to ensure the accuracy of the calculations, Lasermet cannot guarantee that results are 100% accurate. For safety critical applications, users are advised to independently verify all calculations. Lasermet cannot be held responsible for any inaccuracies or misuse of the information provided. Use of this software implies acceptance of these terms.</Text>
                </div>
              </Flex> */}

                  {/* Footer */}
                  <Row justify='center' style={{ margin: '3% 0 3% 0' }}>
                    <h2 style={{ color: '#1d1f8c', textAlign:"center" }}><i>Innovative laser calculations from the experts in laser safety</i></h2>
        
                  </Row>
                </>
              ) : handleClick === "profile" ? (
                <Row justify={"center"}>
                  <EditProfile
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    handleOk={handleOk}
                    handleCancel={handleCancel}
                    myUser={myUser}
                    setMyUser={setMyUser}
                    isLocal={isLocal}
                  />
             
                </Row>
              ) : handleClick === "laserProfile" ? (
                <Row justify={"center"}>
                  {myLaserProfile !== null ? (
                    <>
                      <LaserProfileModal
                        myUser={myUser}
                        isLocal = {isLocal}
                        laserProfileVisibility={laserProfileVisibility}
                        setLaserProfileVisibility={setLaserProfileVisibility}
                        deleteIsLoading={deleteIsLoading}
                        handleDeleteProfile={handleDeleteProfile}
                        myLaserProfile={myLaserProfile}
                      />
                              
                      {/* Laser Profile Modal */}
                    </>
                  ) : null}
                </Row>
              ) : handleClick === "billing" ? (
                <>
                  <Row justify={"center"}>
                    <Card style={{ width: "60vw", display: "flex", justifyContent: "center", textAlign:  "center" }}>
                      <Typography.Text>
                      <h2 style={{color: "#1d1f8c"}}>
                        {isSubscribed === true ? ("You are Subscribed: Unlimited Calculations") 
                        : isFreeTrial === true ? ("Free Trial Activated") 
                        : isFreeTrial === false ? ("Free Trial Expired") 
                        : isPreAuthorized === true ? ("Activate Free Trial")
                        : (<>Your Available Calculations:  <span style={{ color: numberOfCalcs < 4 ? "#d82424" : "#10c96d" }}> {numberOfCalcs}</span> </> ) }
                      </h2>
                      </Typography.Text>
                    </Card>
                  </Row>
                  <SubscriptionPlan isLocal={isLocal} myUser={myUser} isSubscribed={isSubscribed} isFreeTrial={isFreeTrial} freeTrialActivated={freeTrialActivated} isPreAuthorized={isPreAuthorized} userLocation={userLocation}/>
                </>  
              )
                : null}

            </Content>
          </Layout>
        </Layout>
            <Footer style={{
                backgroundColor: "#1d1f8c",
                height: "12vh",
                position: "sticky",
                width: '100%',
                top: "100vh",
                bottom: 0

              }}>
                <p style={{ color: "#ffcc01", marginLeft: "20px" }}>RealTime Laser Safety Software</p>
                <p style={{ color: "white", marginLeft: "20px" }}>{year} Lasermet</p>
              </Footer>
     
      </Spin>
    </>




  )
}

export default Home
