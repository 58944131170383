import { Alert, Button, Form, Input, Layout, Row, Space, Typography } from 'antd'
import Password from 'antd/es/input/Password'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import miniIcon from '../assets/mini-icon.png'
import { Content, Footer, Header } from 'antd/es/layout/layout'
import Axios from 'axios'

const Register = (props) => {
    const navigate = useNavigate()
    const isLocal = props.isLocal
    const year = new Date().getFullYear();
    const [form] = Form.useForm()
    const [errorMessage, setErrorMessage] = useState(null)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const initialValues = {
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: ''
    }

    const handleSubmit = () => {
        setIsSubmitting(true)
        setErrorMessage(null)
        console.log(form.getFieldsValue())




        let url = () => {
            if (isLocal === true) {
                return 'http://localhost:8000/register'
            } else {
                return 'api/register'
            }
        }

        Axios({
            method: 'post',
            url: url(),
            data: form.getFieldsValue(),
            withCredentials: true
        })
            .then((response) => {
                console.log(response)
                // this branch means the email has been sent and will now transfer user to success message.
                navigate('/confirm')
            })
            .catch((error) => {
                setIsSubmitting(false)
                setErrorMessage(error.response.data.message)
            })


    };
    return (
        <Layout>
            <Content style={{ backgroundColor: '#EFEFEF' }}>
                <Row style={{ display: 'flex', padding: '100px 0px 0px 0px', justifyContent: 'center' }}>
                    <img src={miniIcon} alt="Lasermet Alternative Logo" />
                </Row>

                <Row style={{ display: 'flex', padding: '20px 0px 0px 0px', justifyContent: 'center' }}>
                    <Typography.Text style={{ color: '#3a4e84', fontSize: '30px', fontWeight: '500' }}>
                        Lasermet Laser Safety Software
                    </Typography.Text>
                </Row>

                <Row justify='center'>
                    <div style={{ width: '600px', backgroundColor: '#FFFFFF', padding: '24px', margin: '50px', borderRadius: '24px' }}>
                        <Form
                            initialValues={initialValues}
                            onFinish={handleSubmit}
                            labelAlign={'right'}
                            labelCol={{ span: 7 }}
                            form={form}>
                            <Form.Item
                                name='username'
                                label='Username'
                                rules={[{ required: true, message: 'Please provide a username.' }]}>
                                <Input />
                            </Form.Item>


                            <Form.Item name='email'
                                label='Email'
                                rules={[{ required: true, message: 'The activation key is sent in this email so it is important to have a valid email address entered here.' }, { type: 'email', message: 'Invalid email!' }]}>
                                <Input placeholder='test@example.com' />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password autoComplete='new-password' />
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                label="Confirm Password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The new password that you entered do not match!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                name='fullName'
                                label='Full Name'>
                                <Form.Item
                                    name='firstName'
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px' }}
                                    rules={[{ required: true, message: 'First name required!' }]}>
                                    <Input placeholder='First name' />
                                </Form.Item>

                                <Form.Item
                                    name='lastName'
                                    style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
                                    rules={[{ required: true, message: 'Last name required!' }]}>
                                    <Input placeholder='Last name' />
                                </Form.Item>
                            </Form.Item>

                            <Button loading={isSubmitting} htmlType='submit' type='primary' size='large' block>Register</Button>
                            {errorMessage !== null ? (
                                <Alert
                                    style={{ margin: '24px 0' }}
                                    description={errorMessage}
                                    type='error'
                                    showIcon
                                />
                            ) : null}
                            <Row justify='center'>
                                <Row>
                                    <Typography>
                                        Already have an account? <NavLink to='/'>Login</NavLink> here.
                                    </Typography>
                                </Row>
                            </Row>
                        </Form>
                    </div>
                </Row>
            </Content>
            <Footer style={{
                textAlign: 'center',
                backgroundColor: '#3a4e84',
                color: '#f5f5f5',
                fontSize: '16px'
            }}
            >
                Lasermet Philippines © {year}. Created by Lasermet Philippines.
            </Footer>
        </Layout>
    )
}

export default Register