import { CiOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { Form, InputNumber, Select, Space, Tooltip } from 'antd'
import React, { useState } from 'react'

const Field = ({ name, label, unit, min = 0.1, max, denoms, placeholder, isSpecial, tabIndex = null, toolTipText = null, isCW, isCustom }) => {

    const [customMax, setCustomMax] = useState(max)
    const [customMin, setCustomMin] = useState(max)
    const [customPlaceholder, setCustomPlaceholder] = useState(placeholder)

    let options = null
    if (Array.isArray(denoms)) {
        options = denoms.map(denom => {
            switch (denom) {
                case "degree": {
                    return {
                        value: denom,
                        label: '°'
                    }
                }
                case "f": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }
                case "p": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }
                case "n": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }
                case "u": {
                    return {
                        value: denom,
                        label: "μ" + unit
                    }
                }
                case false: {
                    return {
                        value: false,
                        label: unit
                    }
                }
                case "m": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }
                case "c": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }

                case "k": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }

                case "M": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }

                case "G": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }

                case "T": {
                    return {
                        value: denom,
                        label: denom + unit
                    }
                }

            }
        })
    }







    const handleUnitsChange = (value) => {
        console.log(`switching to ${value}`)
        switch (value) {
            case false: {
                setCustomMax(0.25)
                setCustomPlaceholder('Time must be between 0 ~ 0.25 s')
                break
            }
            case 'm': {
                setCustomMax(250)
                setCustomPlaceholder(`Time must between 0 ~ 250 ms`)
                break
            }
            case 'u': {
                setCustomMax(250000)
                setCustomPlaceholder(`Time must between 0 ~ 250,000 μs`)
                break
            }
            case 'n': {
                setCustomMax(250000000)
                setCustomPlaceholder(`Time must between 0 ~ 250,000,000 ns`)
                break
            }
            case 'p': {
                setCustomMax(250000000000)
                setCustomPlaceholder(`Time must between 0 ~ 250,000,000,000 ps`)
                break
            }
            case 'f': {
                setCustomMax(250000000000000)
                setCustomPlaceholder(`Time must between 0 ~ 250,000,000,000,000 fs`)
                break
            }

        }
    }

    const handleUnitsChangeCW = (value) => {
        console.log(`switching to ${value}`)
        switch (value) {
            case false: {
                setCustomMin(0.25)
                setCustomPlaceholder('Time must be between 0.25 s ~ 10⁶ s')
                break
            }
            case 'm': {
                setCustomMin(250)
                setCustomPlaceholder(`Time must be between 250 ms ~ 10⁹ ms`)
                break
            }
            case 'u': {
                setCustomMin(250000)
                setCustomPlaceholder(`Time must be between 250 x 10³ μs ~ 10¹² μs`)
                break
            }
            case 'n': {
                setCustomMin(250000000)
                setCustomPlaceholder(`Time must be between 250 x 10⁶ ns ~ 10¹⁵ ns`)
                break
            }
            case 'p': {
                setCustomMin(250000000000)
                setCustomPlaceholder(`Time must be between 250 x 10⁹ ps ~ 10¹⁸ ps`)
                break
            }
            case 'f': {
                setCustomMin(250000000000000)
                setCustomPlaceholder(`Time must be between 250 x 10¹² fs ~ 10²¹ fs`)
                break
            }

        }
    }

    return (
        <>
            <Tooltip
                mouseEnterDelay={0.5}
                title={toolTipText}
                placement='left'
                destroyTooltipOnHide>
                <Form.Item label={label} name={[...name, 'num']} rules={[{ required: true, message: 'Please specify value.' }]}>
                    <InputNumber
                        tabIndex={tabIndex}
                        onClick={() => { }}
                        min={(() => {
                            if (isSpecial) {
                                return min
                            }
                            if (isCW) {
                                return customMin
                            }
                            return null
                        })()}
                        max={(() => {
                            if (isSpecial) {
                                return customMax
                            }
                            if (isCustom) {
                                return max
                            }
                            return null
                        })}
                        placeholder={customPlaceholder}
                        style={{ width: '100%' }}
                        addonAfter={
                            options === null ? (
                                <div style={{ width: 60 }}>{unit}</div>
                            ) : (
                                <>
                                    <Form.Item noStyle
                                        name={[...name, 'unit']}
                                        rules={[{ required: true, message: 'Please specify unit' }]}>
                                        <Select
                                            tabIndex={-1}
                                            onChange={(e) => {
                                                if (isSpecial) {
                                                    handleUnitsChange(e)
                                                }

                                                if (isCW) {
                                                    handleUnitsChangeCW(e)
                                                }
                                                return null
                                            }} style={{ width: 80 }} options={options} min={null} max={null} />
                                    </Form.Item>

                                </>
                            )
                        } />
                </Form.Item>
            </Tooltip>
        </>
    )
}

export default Field