import { Alert, Button, Form, Input, InputNumber, Result, Row, Typography } from 'antd'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Axios from 'axios'

const RegistrationSuccess = (props) => {
    const isLocal = props.isLocal
    const [form] = Form.useForm()
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const navigate = useNavigate()
    let initialValues = {
        username: null,
        confirmationCode: null
    }
    if (props.username) {
        initialValues.username = props.username
    }


    let url = () => {
        if (isLocal === true) {
            return 'http://localhost:8000/confirm'
        } else {
            return 'api/confirm'
        }
    }



    const handleSubmit = () => {
        setIsSubmitting(true)
        Axios({
            method: 'post',
            url: url(),
            data: form.getFieldsValue(),
            withCredentials: true
        }).then((response) => {
            navigate('/')
        }).catch((error) => {
            console.log(error.response.data.error)
            setErrorMessage(error.response.data.error)
        }).finally(() => {
            setIsSubmitting(false)
        })
    }
    return (
        <Result
            status='success'
            title='Activate your account!'
            subTitle='Check your email for the confirmation code and enter it here:'
            extra={[
                <Row justify='center'>
                    <div style={{ width: '500px' }}>
                        <Form
                            initialValues={initialValues}
                            onFinish={handleSubmit}
                            form={form}
                            labelCol={{ span: 7 }}>
                            <Form.Item
                                name='username'
                                label='Username'
                                rules={[{ required: true, message: 'Please enter your username' }]}>
                                <Input size='large' />
                            </Form.Item>
                            <Form.Item
                                name='confirmationCode'
                                label='Confirmation Code'
                                rules={[{ required: true, message: 'Check your email for the activation code' }]}>
                                <Input size='large' />
                            </Form.Item>



                            <Button htmlType='submit' loading={isSubmitting} type='primary' block size='large'>Activate Account</Button>
                        </Form>
                        {errorMessage !== null ? (
                            <Row style={{ margin: '16px 0' }} justify={'center'}>
                                <Alert
                                    type='error'
                                    description={errorMessage}
                                    showIcon
                                />
                            </Row>
                        ) : null}
                        <Row style={{ margin: '8px 0' }} justify={'center'}><Typography>Account already activated? <NavLink to='/'>Login</NavLink> here!</Typography></Row>
                    </div>
                </Row>
            ]}
        />
    )
}

export default RegistrationSuccess