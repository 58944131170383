import { Card, Form, InputNumber, Radio } from "antd";
import React from "react";
import Field from "../../../../components/Field";

const FO_CWCalculator = () => {
  return (
    <>
      <Card
        style={{ margin: "24px 0 0" }}
        size="small"
        title="Repetitively Pulsed Settings"
      >
      <Card title='Laser Settings'
          size='small'
          style={{ margin: '24px 0 0' }}>
        
        <Field
          name={["FO_repetitivelyPulsed", "wavelength"]}
          label="Wavelength"
          unit="m"
          denoms={["n", "u"]}
          min ={180}
          max={1000000}
          placeholder="1800nm to 1,000,000m"
          toolTipText={`The laser's wavelength.`}
        />

        <Field
          name={["FO_repetitivelyPulsed", "time"]}
          label="Exposure Duration"
          unit="s"
          denoms={['f', 'p', 'n', 'u', 'm', false]}
          placeholder={`Enter emission duration`}
          toolTipText={`The duration of continuous emission of laser radiation incident upon the human body.`}
        />
        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}>
          {({ getFieldValue }) => {
            let sourceType = getFieldValue(["sourceType"]);
            console.log(sourceType);
            return sourceType === "singlemode" ? (
              <Field
                name={["FO_repetitivelyPulsed", "diameter"]}
                label="Fiber Core Diameter"
                unit="m"
                denoms={["u"]}
                placeholder={`Enter Fiber Core's Diameter`}
                toolTipText={`The diameter of the source of the laser beam.`}
              />
            ) : sourceType === "multimodeSI" || "multimodeGI" ? (
              <Field
                name={["FO_repetitivelyPulsed", "numerical_aperture"]}
                label="Numerical Aperture"
                placeholder={`Enter Numerical Aperture`}
                toolTipText={`The diameter of the source of the laser beam.`}
              />
            ) : null;
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}
        >
          {({ getFieldValue }) => {
            let sourceType = getFieldValue(["sourceType"]);
            console.log(sourceType);
            return sourceType === "multimodeGI" ? (
              <Field
                name={["FO_repetitivelyPulsed", "IGF"]}
                label="Index Grading Factor"
                placeholder={`Enter Index Grading Factor`}
                toolTipText={`The diameter of the source of the laser beam.`}
              />
            ) : null;
          }}
        </Form.Item>

        <Field
          name={["FO_repetitivelyPulsed", "distance"]}
          label="Distance to target"
          unit="m"
          denoms={["k", false, "c", "m", "u", "n", "p", "f"]}
          placeholder={`Enter distance from the laser`}
          toolTipText={`The distance between the source of the laser beam and the target.`}
        />
        </Card>  
        <Card
                size='small'
                title='Laser Output'
                style={{ margin: '24px 0 0' }}>
                <Form.Item
                    name={['FO_repetitivelyPulsed', 'outputUnits']}
                    label='Laser Output Units'>
                    <Radio.Group optionType='default'>
                        <Radio.Button value='peakPower'>Peak Power</Radio.Button>
                        <Radio.Button value='averagePower'>Average Power</Radio.Button>
                    </Radio.Group>
                </Form.Item>

                <Form.Item noStyle shouldUpdate={(prev, curr) => prev.FO_repetitivelyPulsed.outputUnits !== curr.FO_repetitivelyPulsed.outputUnits}>
                    {({ getFieldValue }) => {
                        let outputUnits = getFieldValue(['FO_repetitivelyPulsed', 'outputUnits'])
                        return (
                                outputUnits === 'averagePower' ? (
                                    <Field
                                        name={['FO_repetitivelyPulsed', 'averagePower']}
                                        label='Average Power'
                                        unit='W'
                                        denoms={['f', 'p', 'n', 'u', 'm', false, 'k', 'M']}
                                        min={0.1}
                                        placeholder='Enter output average power'
                                        toolTipText={`The output of the laser expressed as Average Power (W).`}
                                    />
                                ) : (
                                    outputUnits === 'peakPower' ? (
                                        <Field
                                            name={['FO_repetitivelyPulsed', 'peakPower']}
                                            label='Peak Power'
                                            unit='W'
                                            denoms={['f', 'p', 'n', 'u', 'm', false, 'k', 'M']}
                                            placeholder='Enter peak power'
                                            min={0.1}
                                            toolTipText={`The output of the laser expressed as Peak Power (W).`}
                                        />
                                    ) : null
                                )
                            )
                    }}
                </Form.Item>
                <Field
                  name={["FO_repetitivelyPulsed", "duration"]}
                  label="Pulse Duration"
                  unit="s"
                  denoms={['f', 'p', 'n', 'u', 'm', false]}
                  placeholder={`Enter emission duration`}
                  toolTipText={`The duration of continuous emission of laser radiation incident upon the human body.`}
                />
                <Field
                  name={["FO_repetitivelyPulsed", "pulseFrequency"]}
                  label="Pulse Frequency"
                  unit="Hz"
                  denoms={['u', 'm', false, 'k', 'M']}
                  placeholder={`Enter emission duration`}
                  toolTipText={`The duration of continuous emission of laser radiation incident upon the human body.`}
                  />
            </Card>
          </Card>
    </>
  );
};

export default FO_CWCalculator;
