import { Row, Typography, Tabs, Divider, Tooltip, Space, Col, Card, Flex } from "antd";
import ResultCard from "../../../../../components/ResultCard";
import React, { useState } from "react";
import { LaserClassResults } from "../../../../../components/LaserClassResults";
import { Eyewear } from "../../../../../components/TestAndLabelLinks";
import eye from "../../../../../assets/Icons/eye black.png"
import hand from "../../../../../assets/Icons/hand black.png"
import beam from "../../../../../assets/Icons/beam black.png"
import { LaserClassResultsV2 } from "../MPE_AEL_Results/LaserClassResultsV2";
import LaserClassVerdict from "../MPE_AEL_Results/LaserClassVerdict";
import { Infograph } from "../../../../../components/infographicsModal/Infograph";

export function ResultFOsinglePulseMGI(props) {
  const textCenter = props.textCenter
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [info, setInfo] = useState(null)

  const handleClick = (e) => {
    setIsModalOpen(true)
    setInfo(e)
    console.log("clicked", e)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }
  return (
  <Tabs items={[{
    key: 1,
    label: "MPE Details",
    children: <>
      <Infograph
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          handleCancel={handleCancel}
          info={info}
        />
      <Card style={{marginBottom: "20px"}}>
        <Divider orientation="center"><h4><img src={eye} width={50} style={{marginRight:"5px"}}/><strong>EYE</strong></h4></Divider>
        <Row justify="center" align="center">
          <ResultCard>
            <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which, under normal circumstances, the eye may be exposed without suffering adverse effects."}>
            <strong style={textCenter}>MPE</strong>
            <Typography style={textCenter}>
            {(() => {
                if (props.units === 'joules') {
                  return props.result.MPE.joulesPerSqmPretty.eng + 'J/m²'
                }else if (props.units === 'watts') {
                  return props.result.MPE.wattsPerSqmPretty.eng + 'W/m²'
                }
              })()}
              </Typography>
            </Tooltip>
          </ResultCard>
          <ResultCard>
            <strong style={textCenter}>
              Accessible Emission
            </strong>
            <Space>
              <Tooltip title={"The value of the Radiant Exposure taking into account the applicable beam area"}>
              <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
              <strong>
                <Typography style={textCenter}>
                {(() => {
                  if (props.units === 'joules') {
                    return props.result.AE.joulesPerSqmPretty.eng + 'J/m²'
                  }else if (props.units === 'watts') {
                    return props.result.AE.wattsPerSqmPretty.eng + 'W/m²'
                  }
                  })()}
                </Typography>
              </strong>
            </Tooltip>
            <Divider type="vertical" style={{ height: "40px" }}></Divider>
                <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                  <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                  <strong>
                    <Typography style={textCenter}>
                      {(() => {
                        if (props.units === 'joules') {
                          return props.result.AE.actual_joulesPerSqmPretty.eng + 'J/m²'
                        } else if ( props.units === 'watts') {
                          return props.result.AE.actual_wattsPerSqmPretty.eng + 'W/m²'
                          
                        }
                      })()}
                    </Typography>
                  </strong>
              </Tooltip>
            </Space>
          </ResultCard>
          <ResultCard>
            <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the eye."}>  
              <strong style={textCenter}>
                Eye Safety Ratio
              </strong>
              <strong style={props.result.safetyratio.joules > 1 ? {
                ...textCenter,
                color: "#d82424"
              } : props.result.safetyratio.joules <= 1 && props.result.safetyratio.joules >= 0.5 ? {
                ...textCenter,
                color: "#E9B824"
              } : props.result.safetyratio.joules < 0.5 ? {
                ...textCenter,
                color: "#10c96d"
              } : null}>
                {props.result.safetyratio.pretty.pretty}
              </strong>
            </Tooltip>
          </ResultCard>
        </Row>
        <Card style={{
                    backgroundColor: (() => {
                      if (props.result.safetyratio.joules > 1) {
                        return "#d82424"
                      }
                      else if (props.result.safetyratio.joules <= 0.5) {
                        return "#10c96d"
                      }
                      else if (props.result.safetyratio.joules > 0.5 || props.result.safetyratio.joules <= 1) {
                        return "#ffa200"
                      }
                    })()
            }}>
            <Typography style={textCenter}>
              <strong style={{color: "#ffffff"}}>
              {(() => {
              
                        if (props.result.safetyratio.joules > 1) {
                          return "The configuration that has been entered is Dangerous for the Eye!"
                        }
                        else if (props.result.safetyratio.joules <= 0.5) {
                          return "The configuration that has been entered is Safe for the Eye!"
                        }
                        else if (props.result.safetyratio.joules > 0.5 || props.result.safetyratio.joules <= 1) {
                          return "The configuration that has been entered is Marginally Safe for the Eye!"
                        }
              })()}
            </strong>
            </Typography>
        </Card>
      </Card> 
      <Card style={{marginBottom: "20px"}}>
        <Divider><h4><img src={hand} width={50} style={{marginRight:"5px"}}/><strong> SKIN </strong> </h4></Divider>
        <Row justify="center" align="center">
                <ResultCard>
                  <strong style={textCenter}>
                    Skin MPE
                  </strong>
                  <Tooltip title={"Maximum Permissible Exposure, this value is the level of laser radiation to which, under normal circumstances, the skin may be exposed without suffering adverse effects."}>
                    <Typography style={textCenter}>
                      {(() => {
                        if (props.units === 'joules'){ 
                          return props.result.skinMPE.joulesPerSqmPretty.eng + 'J/m²';
                        }
                        if (props.units === 'watts'){ 
                          return props.result.skinMPE.wattsPerSqmPretty.eng + 'W/m²';
                      }
                      })()}
                    </Typography>
                  </Tooltip>
                </ResultCard>
            
                <ResultCard>
                  <strong style={textCenter}>
                    Skin AE
                  </strong>
                  <Space>
                    <Tooltip title={"The value of the Radiant Exposure to the taking into account the applicable beam area"}>
                      <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>AE</p>
                      <strong>
                        <Typography style={textCenter}>
                          {(() => {
                            if ( props.units === 'joules'){
                              return props.result.skinAE.joulesPerSqmPretty.eng + 'J/m²';
                            }
                            else if ( props.units === 'watts'){
                              return props.result.skinAE.wattsPerSqmPretty.eng + 'W/m²'
                            }
                          })()}

                        </Typography>
                      </strong>
                    </Tooltip>
                    <Divider type="vertical" style={{ height: "40px" }}></Divider>
                    <Tooltip title={"The value of the Radiant Exposure based on the spot size"}>
                      <p style={{ "marginBottom": 1, display: 'flex', justifyContent: 'center' }}>Actual AE</p>
                      <strong>
                        <Typography style={textCenter}>
                          {(() => {
                            if (props.units === 'joules'){
                              return props.result.skinAE.actual_joulesPerSqmPretty.eng + 'J/m²'
                            }
                            else if (props.units === 'watts'){
                              return props.result.skinAE.actual_wattsPerSqmPretty.eng + 'W/m²'
                            }
                          })()}

                        </Typography>
                      </strong>
                    </Tooltip>
                  </Space>
                </ResultCard>
            
                <ResultCard>
                  <strong style={textCenter}>
                    Skin Safety Ratio
                  </strong>
                  <Tooltip title={"The safety margin between the Accessible Emissionand the Maximum Permissible Exposure (MPE) limit for the skin."}>
                    <strong style={props.result.skinMPEExcess.joules > 1 ? {
                      ...textCenter,
                      color: "#d82424"
                    } : props.result.skinMPEExcess.joules <= 1 && props.result.skinMPEExcess.joules >= 0.5 ? {
                      ...textCenter,
                      color: "#ffa200"
                    } : props.result.skinMPEExcess.joules < 0.5 ? {
                      ...textCenter,
                      color: "#10c96d"
                    } : null}>
                      {props.result.skinMPEExcess.joulesPretty.pretty}
                    </strong>
                  </Tooltip>
                </ResultCard>
        
        </Row>
        <Card style={{
                    backgroundColor: (() => {
                      if (props.result.skinMPEExcess.joules > 1) {
                        return "#d82424"
                      }
                      else if (props.result.skinMPEExcess.joules <= 0.5) {
                        return "#10c96d"
                      }
                      else if (props.result.skinMPEExcess.joules > 0.5 || props.result.skinMPEExcess.joules <= 1) {
                        return "#ffa200"
                      }
                    })()
            }}>
            <Typography style={textCenter}>
              <strong style={{color: "#ffffff"}}>
              {(() => {
              
                        if (props.result.skinMPEExcess.joules > 1) {
                          return "The configuration that has been entered is Dangerous for the Skin!"
                        }
                        else if (props.result.skinMPEExcess.joules <= 0.5) {
                          return "The configuration that has been entered is Safe for the Skin!"
                        }
                        else if (props.result.skinMPEExcess.joules > 0.5 || props.result.skinMPEExcess.joules <= 1) {
                          return "The configuration that has been entered is Marginally Safe for the Skin!"
                        }
              })()}
            </strong>
            </Typography>
        </Card>
      </Card>
      <Card>
        <Divider orientation="center">
          <h4><img src={beam} width={50} /> <strong>BEAM PROPERTIES</strong></h4>
        </Divider>
        <Flex justify="center" gap="middle" wrap="wrap">
        <ResultCard>
          <div onClick={() => { handleClick("NOHD") }} style={{cursor:"pointer"}}>
            <Tooltip title={"Distance from the output aperture beyond which the beam irradiance or radiant exposure remains below the appropriate corneal maximum permissible exposure."}>
              <strong style={textCenter}>
                N.O.H.D.
              </strong>
              <Typography style={textCenter}>
                {props.result.nohd.eng=== "NaN" ? 0 + " m"  : props.result.nohd.eng+"m" }
                </Typography>
            </Tooltip>
            </div>
          </ResultCard>

          <ResultCard>
            <div onClick={() => { handleClick("ENOHD") }} style={{cursor:"pointer"}}>
            <Tooltip title={"Distance from the output aperture where the beam irradiance or radiant exposure stays below the designated corneal Maximum Permissible Exposure (MPE) when observed through optical aids."}>
              <strong style={textCenter}>
              Extended N.O.H.D.
              </strong>
              <Typography style={textCenter}>
                {props.result.xnohd.eng=== "NaN" ? 0 + " m"  : props.result.xnohd.eng+"m" }
                </Typography>
            </Tooltip>
            </div>
          </ResultCard>
          <ResultCard>
            <div onClick={() => { handleClick("Spot Size") }} style={{cursor:"pointer"}}>
            <Tooltip title={"The diameter of the beam at the target distance."}>
              <strong style={textCenter }>  Spot Size</strong>
                <Typography style={textCenter}>
                {props.result.spotsize.eng}m
                </Typography>
            </Tooltip>
            </div>
          </ResultCard>
          <ResultCard>
            <div onClick={()=>{handleClick("limit aperture")}} style={{cursor:"pointer"}}> 
            <Tooltip title={"The diameter of a circular area over which the irradiance or radiant exposure is to be averaged."}>
              <strong style={textCenter}>
                Limit Aperture
              </strong>
              <Typography style={textCenter}>
                Eye: {props.result.limitingAperture.eye} mm
              </Typography>
              <Typography style={textCenter}>
                Skin: {props.result.limitingAperture.skin}{" "}
                mm
              </Typography>
            </Tooltip>
            </div>
          </ResultCard>
          <ResultCard>
            <div onClick={() => { handleClick("beam divergence") }} style={{cursor:"pointer"}}>
              <Tooltip title={'The angular measure of the increase in beam diameter with distance from which the beam emerges.'}>
              <strong style={textCenter }>  Beam Divergence</strong>
                <Typography style={textCenter}>
                {props.result.beamDivergence.degrees.pretty}°
                </Typography>
              </Tooltip>
            </div>
          </ResultCard>
          <ResultCard>
            <div onClick={()=>{handleClick("applicable beam area")}} style={{cursor:"pointer"}}> 
              <Tooltip title={'Area of the spot size or limit aperture - whichever is the largest.'}>
                <strong style={textCenter}>
                  Applicable Beam Area
                </strong>

                <Typography style={textCenter}>
                  Eye: {(() => {
                    if (props.result.laserOperation === 'FO_singlePulse')
                      return props.result.applicableBeamArea.applicableBeamAreaEyePrettiest;
                  })()}
                </Typography>

                <Typography style={textCenter}>
                  Skin: {(() => {
                    if (props.result.laserOperation === 'FO_singlePulse')
                      return props.result.applicableBeamArea.applicableBeamAreaSkinPrettiest;
                  })()}
                </Typography>
              </Tooltip>
            </div>
          </ResultCard>
          <ResultCard  style={{width: "200px", height: "100px",display:"flex", justifyContent: "center", alignItems:"center"}}>
            <div onClick={() => { handleClick("spectrum") }} style={{cursor:"pointer"}}> 
              <strong style={textCenter}>
                Spectrum
                </strong>
              <Row justify={"center"}>
                <div style={{
                      width: "30px",
                      height: "30px",
                        backgroundColor: (() => {
                          if (props.result.spectrum.range == "Infrared") {
                            return "maroon"
                          }
                          else if (props.result.spectrum.range == "Ultraviolet") {
                            return "purple"
                          }
                          return "rgb("+props.result.spectrum.rgb +")"
                        })(),
                        borderRadius: 100, 
                        ustifyContent: "center"
                      }}>
                </div>
              </Row>
              <Row justify={"center"}>{props.result.spectrum.region}</Row>
            </div>
          </ResultCard>
        </Flex>
      </Card>
    </>
  }, {
    key: 2,
    label: "More Information",
    children: <>
      <Row align="center" justify="center">
        <Divider orientation="center">
          Correction Factors & Time Bases
        </Divider>
        {props.result.correctionFactors.map(cf => {
          if (cf.val !== null) {
            return <ResultCard>
              <strong style={textCenter}>
                {cf.name}
              </strong>
              <Typography>
                {cf.pretty}
              </Typography>
            </ResultCard>;
          }
        })}
      </Row>
    </>
  },{
      key: 3,
      label: "Laser Class Details",
    children: (
        <>
        <Row justify="space-evenly">
        <Divider orientation="center">
          Accessible Emission Limits
        </Divider>
          {/* <LaserClassResults laserClass={props.result.laserClass} /> */}
          <LaserClassResultsV2 laserClass={props.result.laserClass} condition={1} />
          <LaserClassResultsV2 laserClass={props.result.laserClass} condition={2} />
          <LaserClassResultsV2 laserClass={props.result.laserClass} condition={3} />
        </Row>
        <LaserClassVerdict laserClass={props.result.laserClass} />
        </>
      ),
    },
    {
      key: 4,
      label: "Laser Protective Eyewear",
      children: (
        <><Row justify="center" align="center">
          <Divider>Optical Density, LB Rating, and RB Number</Divider>
          <Flex style={{alignItems: "center"}}>
            <strong>Results at {props.result.distance} m</strong>
            <ResultCard>
              <strong style={textCenter}>Nominal O.D.</strong>
              <>
                <Space style={textCenter}>
                  <strong>
                    <Tooltip title="The measure of a laser safety filter or eyewear's ability to attenuate laser radiation. A higher optical density indicates greater attenuation, meaning less laser light is transmitted through the filter or eyewear, providing better protection against laser exposure. ">
                      <Typography>
                        {props.result.NominalOD.OD}
                      </Typography>
                    </Tooltip>
                  </strong>
                </Space>
              </>
            </ResultCard>
            <ResultCard>
              <Tooltip title="The LB number indicates the level of protection provided by glass.">
                <strong style={textCenter}>
                  LB Number for Glass
                </strong>
                <Typography style={textCenter}>
                  {props.result.Glass}
                </Typography>
              </Tooltip>
            </ResultCard>
            <ResultCard>
              <Tooltip title="The LB number indicates the level of protection provided by plastic.">
                <strong style={textCenter}>
                  LB Number for Plastic
                </strong>
                <Typography style={textCenter}>
                  {props.result.Plastic}
                </Typography>
              </Tooltip>
            </ResultCard>
            <ResultCard>
              <Tooltip title="The RB number indicates the classification of eye protection filters for Laser alignment. (EN208)">
                <strong style={textCenter}>
                  RB Number
                </strong>
                <Typography style={textCenter}>
                  {props.result.RBNumber}
                </Typography>
              </Tooltip>
            </ResultCard>
          </Flex>
          <Divider></Divider>

          <Flex style={{alignItems: "center"}}>
            <strong>Results at 0.1m</strong>
            <ResultCard>
              <strong style={textCenter}>
                Nominal O.D.
              </strong>
              <>
                <Space style={textCenter}>
                  <strong>
                    <Tooltip title="The measure of a laser safety filter or eyewear's ability to attenuate laser radiation. A higher optical density indicates greater attenuation, meaning less laser light is transmitted through the filter or eyewear, providing better protection against laser exposure. ">
                      <Typography>
                        {props.result.NominalOD_distance.OD}
                      </Typography>
                    </Tooltip>
                  </strong>
                </Space>
              </>
            </ResultCard>
            <ResultCard>
              <Tooltip title="The LB number indicates the level of protection provided by glass.">
                <strong style={textCenter}>
                  LB Number for Glass
                </strong>
                <>
                  <Space style={textCenter}>
                    <Typography style={textCenter}>
                      {props.result.GlassAverage_Distance}
                    </Typography>
                    <Typography style={textCenter}>
                      {props.result.Glass_Distance}
                    </Typography>
                  </Space>
                </>
              </Tooltip>
            </ResultCard>
            <ResultCard>
              <Tooltip title="The LB number indicates the level of protection provided by plastic.">
                <strong style={textCenter}>
                  LB Number for Plastic
                </strong>
                <Space style={textCenter}>
                  <Typography style={textCenter}>
                    {props.result.PlasticAverage_Distance}
                  </Typography>
                  <Typography style={textCenter}>
                    {props.result.Plastic_Distance}
                  </Typography>
                </Space>
              </Tooltip>
            </ResultCard>
            <ResultCard>
              <Tooltip title="The RB number indicates the classification of eye protection filters for Laser alignment. (EN208)">
                <strong style={textCenter}>
                  RB Number
                </strong>
                <Typography style={textCenter}>
                  {props.result.RBNumber_distance}
                </Typography>
              </Tooltip>
            </ResultCard>
          </Flex>

        </Row>
        <Row justify={'center'}>
          <Eyewear />
        </Row></>
      ),
    },
  ]} />);
}
