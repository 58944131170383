

import Cookies from 'js-cookie'
import React, { useState } from 'react'
import { Navigate, Route } from 'react-router-dom'
import Axios from 'axios'


const ProtectedRoutes = ({ children }) => {
    const [isVerified, setIsVerified] = useState(null)
    let token = Cookies.get('RTLSS_TOKEN')
    if (!token) {
        return (
            <Navigate to='/login' />
        )
    } else {
        return (
            children
        )
        // console.log('Token Found! Verifying...')
        // verifyToken(token)
        //     .then(res => {
        //         console.log(`Protected route: verify success!`)
        //         return (
        //             children
        //         )
        //     })
        //     .catch(err => {
        //         console.log(`Protected route: verify fail!`)
        //     })
    }
}

export default ProtectedRoutes
