import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Logout = ({ handleLogout }) => {
    const navigate = useNavigate()
    const token = Cookies.get('RTLSS_TOKEN')
    useEffect(() => {
        if (token) {
            // There is token.
            // We should log this out.
            Cookies.remove('RTLSS_TOKEN')
            Cookies.remove('RTLSS_user')
            Cookies.remove('RTLSS_Loc')
            handleLogout()
            // Navigate to login page after logging out.
            navigate('/login')
        } else {
            navigate('/login')
        }
    })
}

export default Logout