import { Card, Form, InputNumber } from "antd";
import React from "react";
import Field from "../../../../components/Field";

const FO_SinglePulseCalculator = () => {
  return (
    <>
      <Card
        style={{ margin: "24px 0 0" }}
        size="small"
        title="Single Pulse Settings"
      >
        <Field
          name={["FO_singlePulse", "wavelength"]}
          label="Wavelength"
          unit="m"
          denoms={["n", "u"]}
          min={180}
          max={1000000}
          placeholder="1800nm to 1,000,000m"
          toolTipText={`The laser's wavelength.`}
        />

        <Field
          name={["FO_singlePulse", "duration"]}
          label="Pulse Duration"
          unit="s"
          denoms={['f', 'p', 'n', 'u', 'm']}
          placeholder={`Enter emission duration`}
          toolTipText={`The duration of continuous emission of laser radiation incident upon the human body.`}
        />

        <Field
          name={["FO_singlePulse", "power"]}
          label="Laser Peak Power"
          unit="W"
          denoms={["m", false, "k", "M"]}
          placeholder={`Enter laser's output power`}
          toolTipText={`The laser's rated power expressed in watts.`}
        />

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}>
          {({ getFieldValue }) => {
            let sourceType = getFieldValue(["sourceType"]);
            console.log(sourceType);
            return sourceType === "singlemode" ? (
              <Field
                name={["FO_singlePulse", "diameter"]}
                label="Fiber Core Diameter"
                unit="m"
                denoms={["u"]}
                placeholder={`Enter Fiber Core's Diameter`}
                toolTipText={`The diameter of the source of the laser beam.`}
              />
            ) : sourceType === "multimodeSI" || "multimodeGI" ? (
              <Field
                name={["FO_singlePulse", "numerical_aperture"]}
                label="Numerical Aperture"
                placeholder={`Enter Numerical Aperture`}
                toolTipText={`The diameter of the source of the laser beam.`}
              />
            ) : null;
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prev, curr) => prev.sourceType !== curr.sourceType}
        >
          {({ getFieldValue }) => {
            let sourceType = getFieldValue(["sourceType"]);
            console.log(sourceType);
            return sourceType === "multimodeGI" ? (
              <Field
                name={["FO_singlePulse", "IGF"]}
                label="Index Grading Factor"
                placeholder={`Enter Index Grading Factor`}
                toolTipText={`The diameter of the source of the laser beam.`}
              />
            ) : null;
          }}
        </Form.Item>

        <Field
          name={["FO_singlePulse", "distance"]}
          label="Distance to target"
          unit="m"
          denoms={["k", false, "c", "m", "u", "n", "p", "f"]}
          placeholder={`Enter distance from the laser`}
          toolTipText={`The distance between the source of the laser beam and the target.`}
        />
      </Card>
    </>
  );
};

export default FO_SinglePulseCalculator;
