import { Card, Divider, Flex, Space, Tabs, Row, Tooltip, Typography } from "antd";
import React, { useState } from "react";
import ResultCard from "../../../../../components/ResultCard";
import { LaserClassResults } from "../../../../../components/LaserClassResults";
import { LaserClassResultsV2 } from "../MPE_AEL_Results/LaserClassResultsV2";
import LaserClassVerdict from "../MPE_AEL_Results/LaserClassVerdict";
import { Eyewear } from "../../../../../components/TestAndLabelLinks";
import eye from "../../../../../assets/Icons/eye black.png"
import hand from "../../../../../assets/Icons/hand black.png"
import beam from "../../../../../assets/Icons/beam black.png"
import { LaserClassResultsDiffuseReflective } from "../MPE_AEL_Results/LaserClassResultsDiffuseReflective";
import { Infograph } from "../../../../../components/infographicsModal/Infograph";


export function DRResultSinglePulse({ result, units, laserOperation  }) {
    const textCenter = { display: 'flex', justifyContent: 'center' }
    console.log(`amodia single pulse data: `, result)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [info, setInfo] = useState(null)

    const handleClick = (e) => {
      setIsModalOpen(true)
      setInfo(e)
      console.log("clicked", e)
    }
    const handleCancel = () => {
      setIsModalOpen(false)
    }
    return (
        <>
            <Tabs
                items={[
                    {
                        key: 1,
                        label: 'MPE Details',
                        children:
                            <>
                                <Tabs centered items={[{
                                    key: 1,
                                    label: result.albedo + "% Albedo",
                                    children:
                                        <>
                                            <Infograph
                                                isModalOpen={isModalOpen}
                                                setIsModalOpen={setIsModalOpen}
                                                handleCancel={handleCancel}
                                                info={info}
                                            />
                                            <Card style={{ marginBottom: "20px" }}>
                                                <Divider><h4><img src={eye} width={50} style={{ marginRight: "5px" }} /><strong>EYE</strong></h4></Divider>
                                                <>
                                                    <Flex justify="center" style={{ margin: '12px' }}><strong>Result for {result.albedo}% Albedo</strong></Flex>
                                                    <Flex justify='center' style={{ margin: '10px 10px' }}>
                                                        <Space size='middle'>
                                                            <Card>
                                                                <Space direction="vertical">
                                                                    <Flex justify="center"><strong>MPE</strong></Flex>
                                                                    <Flex justify="center">
                                                                        {(() => {
                                                                            if (units === "joules") {
                                                                                return result.MPE.joulesPerSqmPretty.eng + `J/m²`
                                                                            }
                                                                            else {
                                                                                return result.MPE.wattsPerSqmPretty.eng + `W/m²`
                                                                            }
                                                                        })()}
                                                                    </Flex>
                                                                </Space>
                                                            </Card>

                                                            <Card>
                                                                <Space direction="vertical">
                                                                    <Flex justify="center"><strong>Accessible Emission</strong></Flex>
                                                                    <Flex justify="center">
                                                                        {(() => {
                                                                            if (units === "joules") {
                                                                                return result.AE.albedo_joulesPerSqmPretty
                                                                            }
                                                                            else {
                                                                                return result.AE.albedo_wattsPerSqmPretty
                                                                            }
                                                                        })()}
                                                                    </Flex>
                                                                </Space>
                                                            </Card>

                                                            <Card>
                                                                <Space direction="vertical">
                                                                    <Flex justify="center"><strong>Eye Safety Ratio</strong></Flex>

                                                                    <Flex justify="center"
                                                                        style={(() => {
                                                                            if (result.MPEExcess.albedo_wattsPerSqm >= 1) {
                                                                                return {
                                                                                    color: 'red'
                                                                                }
                                                                            }
                                                                            else if (result.MPEExcess.albedo_wattsPerSqm >= 0.5 && result.MPEExcess.albedo_wattsPerSqm < 1) {
                                                                                return {
                                                                                    color: 'orange'
                                                                                }
                                                                            }
                                                                            else {
                                                                                return {
                                                                                    color: 'green'
                                                                                }
                                                                            }
                                                                        })()}>
                                                                        <strong>{result.MPEExcess.albedo_wattsPerSqmPretty}</strong>
                                                                    </Flex>
                                                                </Space>
                                                            </Card>
                                                        </Space>
                                                    </Flex>
                                                    <Card style={{
                                                        backgroundColor: (() => {
                                                            if (result.MPEExcess.albedo_wattsPerSqm > 1) {
                                                                return "#d82424"
                                                            }
                                                            else if (result.MPEExcess.albedo_wattsPerSqm <= 0.5) {
                                                                return "#10c96d"
                                                            }
                                                            else if (result.MPEExcess.albedo_wattsPerSqm > 0.5 || result.MPEExcess.albedo_wattsPerSqm <= 1) {
                                                                return "#ffa200"
                                                            }
                                                        })()
                                                    }}
                                                    >
                                                        <Flex justify="center">
                                                            <strong style={{ color: "#ffffff" }}>
                                                                {(() => {

                                                                    if (result.MPEExcess.albedo_wattsPerSqm > 1) {
                                                                        return "The configuration that has been entered is Dangerous for the eyes!"
                                                                    }
                                                                    else if (result.MPEExcess.albedo_wattsPerSqm <= 0.5) {
                                                                        return "The configuration that has been entered is Safe for the eyes!"
                                                                    }
                                                                    else if (result.MPEExcess.albedo_wattsPerSqm > 0.5 || result.MPEExcess.albedo_wattsPerSqm <= 1) {
                                                                        return "The configuration that has been entered is Marginally Safe for the eyes!"
                                                                    }
                                                                })()}
                                                            </strong>
                                                        </Flex>
                                                    </Card>
                                                </>
                                            </Card>
                                            <Card style={{ marginBottom: "20px" }}>
                                                <Divider><h4><img src={hand} width={50} style={{ marginRight: "5px" }} /><strong> SKIN </strong> </h4></Divider>

                                                <Flex justify="center" gap={"middle"} style={{ margin: '10px 10px' }}>
                                                    <Card>
                                                        <Space direction="vertical">
                                                            <Flex justify="center"><strong>MPE</strong></Flex>
                                                            <Flex justify="center">
                                                                {(() => {
                                                                    if (units === "joules") {
                                                                        return result.skinMPE.joulesPerSqmPretty.eng + `J/m²`
                                                                    }
                                                                    else {
                                                                        return result.skinMPE.wattsPerSqmPretty.eng + `W/m²`
                                                                    }
                                                                })()}
                                                            </Flex>
                                                        </Space>
                                                    </Card>
                                                    <Card>
                                                        <Space direction="vertical">
                                                            <Flex justify="center"><strong>AE</strong></Flex>
                                                            <Flex justify="center">
                                                                {(() => {
                                                                    if (units === "joules") {
                                                                        return result.skinAE.albedo_joulesPerSqmPretty
                                                                    }
                                                                    else {
                                                                        return result.skinAE.albedo_wattsPerSqmPretty
                                                                    }
                                                                })()}
                                                            </Flex>
                                                        </Space>
                                                    </Card>
                                                    <Card>
                                                        <Space direction="vertical">
                                                            <Flex justify="center"><strong>Skin Safety Ratio</strong></Flex>
                                                            <Flex justify="center"
                                                                style={(() => {
                                                                    if (result.skinMPEExcess.albedo_wattsPerSqmPretty <= 0.5) {
                                                                        return { color: 'green' }
                                                                    }
                                                                    else if (result.skinMPEExcess.albedo_wattsPerSqmPretty > 0.5 && result.skinMPEExcess.albedo_wattsPerSqmPretty <= 1) {
                                                                        return { color: 'orange' }
                                                                    } else {
                                                                        return { color: 'red' }
                                                                    }
                                                                })()}
                                                            ><strong>{result.skinMPEExcess.albedo_wattsPerSqmPretty}</strong></Flex>
                                                        </Space>
                                                    </Card>
                                                </Flex>
                                                <Card style={{
                                                    backgroundColor: (() => {
                                                        if (result.skinMPEExcess.albedo_wattsPerSqm > 1) {
                                                            return "#d82424"
                                                        }
                                                        else if (result.skinMPEExcess.albedo_wattsPerSqm <= 0.5) {
                                                            return "#10c96d"
                                                        }
                                                        else if (result.skinMPEExcess.albedo_wattsPerSqm > 0.5 || result.skinMPEExcess.albedo_wattsPerSqm <= 1) {
                                                            return "#ffa200"
                                                        }
                                                    })()
                                                }}
                                                >
                                                    <Flex justify="center">
                                                        <strong style={{ color: "#ffffff" }}>
                                                            {(() => {

                                                                if (result.skinMPEExcess.albedo_wattsPerSqm > 1) {
                                                                    return "The configuration that has been entered is Dangerous for the skin!"
                                                                }
                                                                else if (result.skinMPEExcess.albedo_wattsPerSqm <= 0.5) {
                                                                    return "The configuration that has been entered is Safe for the skin!"
                                                                }
                                                                else if (result.skinMPEExcess.albedo_wattsPerSqm > 0.5 || result.skinMPEExcess.albedo_wattsPerSqm <= 1) {
                                                                    return "The configuration that has been entered is Marginally Safe for the skin!"
                                                                }
                                                            })()}
                                                        </strong>
                                                    </Flex>
                                                </Card>
                                            </Card>
                                        </>
                                }, result.albedo === 100 ? null : {
                                    key: 2,
                                    label: "100% Albedo",
                                    children:
                                        <>
                                            <Card style={{ marginBottom: "20px" }}>
                                                <Divider><h4><img src={eye} width={50} style={{ marginRight: "5px" }} /><strong>EYE</strong></h4></Divider>
                                                <Flex justify="center" style={{ margin: '12px' }}><strong>Result for 100% Albedo</strong></Flex>

                                                <Flex justify='center' style={{ margin: '10px 10px' }}>
                                                    <Space size='middle'>
                                                        <Card>
                                                            <Space direction="vertical">
                                                                <Flex justify="center"><strong>MPE</strong></Flex>
                                                                <Flex justify="center">
                                                                    {(() => {
                                                                        if (units === "joules") {
                                                                            return result.MPE.joulesPerSqmPretty.eng + `J/m²`
                                                                        }
                                                                        else {
                                                                            return result.MPE.wattsPerSqmPretty.eng + `W/m²`
                                                                        }
                                                                    })()}
                                                                </Flex>
                                                            </Space>
                                                        </Card>

                                                        <Card>
                                                            <Space direction="vertical">
                                                                <Flex justify="center"><strong>Accessible Emission</strong></Flex>
                                                                <Flex justify="center">
                                                                    {(() => {
                                                                        if (units === "joules") {
                                                                            return result.AE.joulesPerSqmPretty
                                                                        }
                                                                        else {
                                                                            return result.AE.wattsPerSqmPretty
                                                                        }
                                                                    })()}
                                                                </Flex>
                                                            </Space>
                                                        </Card>

                                                        <Card>
                                                            <Space direction="vertical">
                                                                <Flex justify="center"><strong>Eye Safety Ratio</strong></Flex>

                                                                <Flex justify="center"
                                                                    style={(() => {
                                                                        if (result.MPEExcess.wattsPerSqm >= 1) {
                                                                            return {
                                                                                color: 'red'
                                                                            }
                                                                        }
                                                                        else if (result.MPEExcess.wattsPerSqm >= 0.5 && result.MPEExcess.wattsPerSqm < 1) {
                                                                            return {
                                                                                color: 'orange'
                                                                            }
                                                                        }
                                                                        else {
                                                                            return {
                                                                                color: 'green'
                                                                            }
                                                                        }
                                                                    })()}>
                                                                    <strong>{result.MPEExcess.wattsPerSqmPretty}</strong>
                                                                </Flex>
                                                            </Space>
                                                        </Card>
                                                    </Space>
                                                </Flex>
                                                <Card style={{
                                                    backgroundColor: (() => {
                                                        if (result.MPEExcess.wattsPerSqm > 1) {
                                                            return "#d82424"
                                                        }
                                                        else if (result.MPEExcess.wattsPerSqm <= 0.5) {
                                                            return "#10c96d"
                                                        }
                                                        else if (result.MPEExcess.wattsPerSqm > 0.5 || result.MPEExcess.wattsPerSqm <= 1) {
                                                            return "#ffa200"
                                                        }
                                                    })()
                                                }}
                                                >
                                                    <Flex justify="center">
                                                        <strong style={{ color: "#ffffff" }}>
                                                            {(() => {

                                                                if (result.MPEExcess.wattsPerSqm > 1) {
                                                                    return "The configuration that has been entered is Dangerous for the eyes!"
                                                                }
                                                                else if (result.MPEExcess.wattsPerSqm <= 0.5) {
                                                                    return "The configuration that has been entered is Safe for the eyes!"
                                                                }
                                                                else if (result.MPEExcess.wattsPerSqm > 0.5 || result.MPEExcess.wattsPerSqm <= 1) {
                                                                    return "The configuration that has been entered is Marginally Safe for the eyes!"
                                                                }
                                                            })()}
                                                        </strong>
                                                    </Flex>
                                                </Card>
                                            </Card>
                                            <Card style={{ marginBottom: "20px" }}>
                                                <Divider><h4><img src={hand} width={50} style={{ marginRight: "5px" }} /><strong> SKIN </strong> </h4></Divider>

                                                <Flex justify="center" gap={"middle"} style={{ margin: '10px 10px' }}>
                                                    <Card>
                                                        <Space direction="vertical">
                                                            <Flex justify="center"><strong>MPE</strong></Flex>
                                                            <Flex justify="center">
                                                                {(() => {
                                                                    if (units === "joules") {
                                                                        return result.skinMPE.joulesPerSqmPretty.eng + `J/m²`
                                                                    }
                                                                    else {
                                                                        return result.skinMPE.wattsPerSqmPretty.eng + `W/m²`
                                                                    }
                                                                })()}
                                                            </Flex>
                                                        </Space>
                                                    </Card>
                                                    <Card>
                                                        <Space direction="vertical">
                                                            <Flex justify="center"><strong>AE</strong></Flex>
                                                            <Flex justify="center">
                                                                {(() => {
                                                                    if (units === "joules") {
                                                                        return result.skinAE.joulesPerSqmPretty
                                                                    }
                                                                    else {
                                                                        return result.skinAE.wattsPerSqmPretty
                                                                    }
                                                                })()}
                                                            </Flex>
                                                        </Space>
                                                    </Card>
                                                    <Card>
                                                        <Space direction="vertical">
                                                            <Flex justify="center"><strong>Skin Safety Ratio</strong></Flex>
                                                            <Flex justify="center"
                                                                style={(() => {
                                                                    if (result.skinMPEExcess.wattsPerSqmPretty <= 0.5) {
                                                                        return { color: 'green' }
                                                                    }
                                                                    else if (result.skinMPEExcess.wattsPerSqmPretty > 0.5 && result.skinMPEExcess.wattsPerSqmPretty <= 1) {
                                                                        return { color: 'orange' }
                                                                    } else {
                                                                        return { color: 'red' }
                                                                    }
                                                                })()}
                                                            ><strong>{result.skinMPEExcess.wattsPerSqmPretty}</strong></Flex>
                                                        </Space>
                                                    </Card>
                                                </Flex>
                                                <Card style={{
                                                    backgroundColor: (() => {
                                                        if (result.skinMPEExcess.wattsPerSqm > 1) {
                                                            return "#d82424"
                                                        }
                                                        else if (result.skinMPEExcess.wattsPerSqm <= 0.5) {
                                                            return "#10c96d"
                                                        }
                                                        else if (result.skinMPEExcess.wattsPerSqm > 0.5 || result.skinMPEExcess.wattsPerSqm <= 1) {
                                                            return "#ffa200"
                                                        }
                                                    })()
                                                }}
                                                >
                                                    <Flex justify="center">
                                                        <strong style={{ color: "#ffffff" }}>
                                                            {(() => {

                                                                if (result.skinMPEExcess.wattsPerSqm > 1) {
                                                                    return "The configuration that has been entered is Dangerous for the skin!"
                                                                }
                                                                else if (result.skinMPEExcess.wattsPerSqm <= 0.5) {
                                                                    return "The configuration that has been entered is Safe for the skin!"
                                                                }
                                                                else if (result.skinMPEExcess.wattsPerSqm > 0.5 || result.skinMPEExcess.wattsPerSqm <= 1) {
                                                                    return "The configuration that has been entered is Marginally Safe for the skin!"
                                                                }
                                                            })()}
                                                        </strong>
                                                    </Flex>
                                                </Card>
                                            </Card>
                                        </>
                                }]} />
                                <Card>
                                    <Divider><h4><img src={beam} width={50} /> <strong>BEAM PROPERTIES</strong></h4></Divider>
                                    <Flex justify="center" gap={"middle"} wrap="wrap">

                                        <Card>
                                        <div onClick={() => { handleClick("NOHD") }} style={{cursor:"pointer"}}>
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>NOHD</strong></Flex>
                                            <Flex justify="center">
                                                {(() => {
                                                    if (result?.NOHD?.isError === true || result?.NOHD?.isError === undefined || result?.NOHD?.isError === null) {
                                                        return <Tooltip title={result?.NOHD?.message}>N/A</Tooltip>
                                                    } else {
                                                        return result?.NOHD?.value?.pretty
                                                    }
                                                })()}
                                            </Flex>
                                        </Space>
                                        </div>
                                        </Card>

                                        <Card>
                                        <div onClick={() => { handleClick("ENOHD") }} style={{cursor:"pointer"}}>
                                        <Space direction="vertical">
                                            <Flex justify="center"><strong>ENOHD</strong></Flex>
                                            <Flex justify="center">
                                                {(() => {
                                                    if (result?.ENOHD?.isError === true || result?.ENOHD?.isError === undefined || result?.ENOHD?.isError === null) {
                                                        return <Tooltip title='ENOHD is only applicable for wavelengths  between 302.5nm ~ 10⁶nm'>N/A</Tooltip>
                                                    } else {
                                                        return result?.ENOHD?.value?.pretty
                                                    }
                                                })()}
                                            </Flex>
                                        </Space>
                                        </div>
                                        </Card>



                                        <Card>
                                            <div onClick={() => { handleClick("Spot Size") }} style={{cursor:"pointer"}}>
                                                <Space direction="vertical">
                                                    <Flex justify="center"><strong>Spot Size</strong></Flex>
                                                    <Flex justify="center">{result.outputBeamDiameter}</Flex>
                                                </Space>
                                            </div>
                                        </Card>
                                        <Card>
                                            <div onClick={()=>{handleClick("limit aperture")}} style={{cursor:"pointer"}}>
                                                <Space direction="vertical">
                                                    <Flex justify="center"><strong>Limit Aperture</strong></Flex>
                                                    <Flex justify="center">{result.limitAperture.eye + ' mm'}</Flex>
                                                    <Flex justify="center">{result.limitAperture.skin + 'mm'}</Flex>
                                                </Space>
                                            </div>
                                        </Card>

                                        <Card>
                                            <div onClick={()=>{handleClick("applicable beam area")}} style={{cursor:"pointer"}}>
                                                <Space direction="vertical">
                                                    <Flex justify="center"><strong>Beam Area</strong></Flex>
                                                    <Flex justify="center">
                                                        <Space direction="vertical">
                                                            <Flex justify="center"><strong>Actual</strong></Flex>
                                                            <Flex>{result.beamArea.actualPretty}</Flex>
                                                        </Space>
                                                        <Divider orientation="center" type="vertical" />
                                                        <Space direction="vertical">
                                                            <Flex><strong>Applicable</strong></Flex>
                                                            <Flex>{result.beamArea.applicablePretty}</Flex>
                                                        </Space>
                                                    </Flex>
                                                </Space>
                                            </div>
                                        </Card>

                                        <Card style={{ width: "200px", height: "100px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                            <div onClick={() => { handleClick("spectrum") }} style={{cursor:"pointer"}}>
                                                <Flex justify="center"><strong> Spectrum</strong></Flex>
                                                <Row justify={"center"}>
                                                    <div style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        backgroundColor: (() => {
                                                            if (result.spectrum.range == "Infrared") {
                                                                return "maroon"
                                                            }
                                                            else if (result.spectrum.range == "Ultraviolet") {
                                                                return "purple"
                                                            }
                                                            return "rgb(" + result.spectrum.rgb + ")"
                                                        })(),
                                                        borderRadius: 100,
                                                        ustifyContent: "center"
                                                    }}>
                                                    </div>
                                                </Row>
                                                <Row justify={"center"}>{result.spectrum.region}</Row>
                                            </div>
                                        </Card>
                                    </Flex>
                                </Card>
                            </>
                    },
                    {
                        key: 2,
                        label: 'More Information',
                        children:
                            <>
                                <Divider>Correction Factors and Break Points</Divider>
                                <Flex justify="center">
                                    <Space>
                                        {result.correctionFactors.map((cf => {
                                            return (
                                                <Card>
                                                    <Space direction="vertical">
                                                        <Flex justify="center"><strong>{cf.name}</strong></Flex>
                                                        <Flex justify="center">{cf.pretty}</Flex>
                                                    </Space>
                                                </Card>
                                            )
                                        }))}
                                    </Space>
                                </Flex>
                                <Divider>Angular Parameters</Divider>
                                <Flex justify="center">
                                    <Space>
                                        <Card>
                                            <Space direction="vertical">
                                                <Flex justify="center"><strong>Angular Subtense</strong></Flex>
                                                <Flex justify="center">{result.angularSubtense}</Flex>
                                            </Space>
                                        </Card>

                                        <Card>
                                            <Space direction="vertical">
                                                <Flex justify="center"><strong>Angle of Acceptance</strong></Flex>
                                                <Flex justify="center">{result.MPE.angleOfAcceptancePretty}</Flex>
                                            </Space>
                                        </Card>
                                    </Space>
                                </Flex>

                            </>
                    },
                    {
                        key: 3,
                        label: "Laser Class Details",
                        children:
                            <>
                                <Row justify="space-evenly">
                                    <Divider orientation="center">  Accessible Emission Limits </Divider>
                                    {/* <LaserClassResultsV2 laserClass={result.laserClass} condition={1} />
                                    <LaserClassResultsV2 laserClass={result.laserClass} condition={3} /> */}
                                    <LaserClassResultsDiffuseReflective condition={1} result={result} laserOperation={laserOperation}/>
                                    <br />
                                    <LaserClassResultsDiffuseReflective condition={3} result={result} laserOperation={laserOperation}/>
                                </Row>
                                <LaserClassVerdict laserClass={result.Class} />
                            </>
                    }, {
                        key: 4,
                        label: "Laser Protective Eyewear",
                        children: <><Row justify="center" align="center">
                            <Divider>
                                Optical Density, LB Rating, and RB Number
                            </Divider>
                            <Flex style={{ display: "flex", alignItems: "center" }}>
                                <strong>
                                    Results at {result.distance} m
                                </strong>
                                <ResultCard>
                                    <strong style={textCenter}>
                                        Nominal O.D.
                                    </strong>
                                    <>
                                        <Space style={textCenter}>
                                            <strong>
                                                <Tooltip title="The measure of a laser safety filter or eyewear's ability to attenuate laser radiation. A higher optical density indicates greater attenuation, meaning less laser light is transmitted through the filter or eyewear, providing better protection against laser exposure. ">
                                                    <Typography>
                                                        {result.NominalOD.OD}
                                                    </Typography>
                                                </Tooltip>
                                            </strong>

                                        </Space>
                                    </>
                                </ResultCard>
                                <ResultCard>
                                    <Tooltip title="The LB number indicates the level of protection provided by glass.">
                                        <strong style={textCenter}>
                                            LB Number for Glass
                                        </strong>
                                        <Typography style={textCenter}>
                                            {result.Glass}
                                        </Typography>
                                    </Tooltip>
                                </ResultCard>
                                <ResultCard>
                                    <Tooltip title="The LB number indicates the level of protection provided by plastic.">
                                        <strong style={textCenter}>
                                            LB Number for Plastic
                                        </strong>
                                        <Typography style={textCenter}>
                                            {result.Plastic}
                                        </Typography>
                                    </Tooltip>
                                </ResultCard>
                                <ResultCard>
                                    <Tooltip title="The RB number indicates the classification of eye protection filters for Laser alignment. (EN208)">
                                        <strong style={textCenter}>
                                        RB Number
                                        </strong>
                                        <Typography style={textCenter}>
                                        {result.RBNumber}
                                        </Typography>
                                    </Tooltip>
                                </ResultCard>
                            </Flex>
                            <Divider></Divider>

                            <Flex style={{ display: "flex", alignItems: "center" }}>
                                <strong>
                                    Results at 0.1m
                                </strong>
                                <ResultCard>
                                    <strong style={textCenter}>
                                        Nominal O.D.
                                    </strong>
                                    <>
                                        <Space style={textCenter}>
                                            <strong>
                                                <Tooltip title="The measure of a laser safety filter or eyewear's ability to attenuate laser radiation. A higher optical density indicates greater attenuation, meaning less laser light is transmitted through the filter or eyewear, providing better protection against laser exposure. ">
                                                    <Typography>
                                                        {result.NominalOD_distance.OD}
                                                    </Typography>
                                                </Tooltip>
                                            </strong>
                                        </Space>
                                    </>
                                </ResultCard>
                                <ResultCard>
                                    <Tooltip title="The LB number indicates the level of protection provided by glass.">
                                        <strong style={textCenter}>
                                            LB Number for Glass
                                        </strong>
                                        <Typography style={textCenter}>
                                            {result.Glass_Distance}
                                        </Typography>
                                    </Tooltip>
                                </ResultCard>
                                <ResultCard>
                                    <Tooltip title="The LB number indicates the level of protection provided by plastic.">
                                        <strong style={textCenter}>
                                            LB Number for Plastic
                                        </strong>
                                        <Typography style={textCenter}>
                                            {result.Plastic_Distance}
                                        </Typography>
                                    </Tooltip>
                                </ResultCard>
                                <ResultCard>
                                    <Tooltip title="The RB number indicates the classification of eye protection filters for Laser alignment. (EN208)">
                                        <strong style={textCenter}>
                                        RB Number
                                        </strong>
                                        <Typography style={textCenter}>
                                        {result.RBNumber_distance}
                                        </Typography>
                                    </Tooltip>
                                </ResultCard>
                            </Flex>
                        </Row>
                            <Row justify={'center'}>
                                <Eyewear />
                            </Row></>
                    }]}
            />
        </>
    );
}
