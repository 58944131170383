/*
================================================================================
FILE        : Login.jsx
AUTHOR      : Kathryn Marie P. Sigaya
DESCRIPTION : JSX file that shows the login page.
COPYRIGHT   : 26 September 2023
REVISION HISTORY
Date: 			     By: 		 Description:
26 September 2023    Sigaya      Creation of file, initial layout using Ant Design framework
18 October 2023      Sigaya      Added mini icon, changed font family to Helvetica Neue, changed
                                 colors to official colors
================================================================================
*/
import React, { useEffect, useState } from "react";
import Axios from 'axios'
import Cookies from "js-cookie";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  theme,
  Typography,
  Flex
} from 'antd';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Link, useNavigate } from 'react-router-dom';
import miniIcon from '../assets/Logo/rtlss logo.png'
import logo from '../assets/Logo/logosidetext.png'


// const {Title} = Typography;

function Login({ setMyUser, myUser, isLocal }) {
  const { Header, Content, Footer } = Layout;
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [flashMessage, setFlashMessage] = useState(null)
  const navigate = useNavigate()
  const [form] = Form.useForm();


  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const year = new Date().getFullYear();
  const handleCloseFlash = () => {
    setFlashMessage(null)
  }

  const onFinish = (values) => {
    const { username, password, remembered } = form.getFieldsValue()
    setIsSubmitting(true)
    Axios({
      method: 'post',
      data: { username: username, password: password },
      url: (() => {
        if (isLocal === true) {
          return 'http://localhost:8000/jwt-login'
        } else {
          return 'api/jwt-login'
        }
      })(),
      withCredentials: true
    })
      .then((response) => {
        fetch('https://ipinfo.io/json?token=7a0cb72fba6b42')
        .then(response => response.json())
        .catch(()=> ({country: "GB", timezone: "Europe" }))
        .then(data => {
          Cookies.set('RTLSS_Loc', JSON.stringify(data), {expires: 1})
          Cookies.set('RTLSS_TOKEN', response.data[0], { expires: 1 })
          Cookies.set('RTLSS_user', JSON.stringify(response.data[1]), { expires: 1 })
          setMyUser({ ...myUser })
          console.log(`token successfully appended.`)
          setFlashMessage({ type: 'success', message: 'Login successful!' })
          navigate('/home')
        })
   
      })
      .catch((err) => {
        setIsSubmitting(false)
        console.log(err)
        setFlashMessage({ type: 'error', message: err.response?.data.error || 'Error' })
      })
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Unable to submit the form. Error log:', errorInfo);
  };

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#1d1f8c',
            fontFamily: 'Helvetica Neue'
          },
          components: {
            Layout: {
              bodyBg: '#efefef',
              footerBg: '#1d1f8c'
            },
            Input: {
              errorActiveShadow: '#d82424'
            }
          }
        }
        }>
        {/*Layout - Applies to the whole web page.*/}
        {/* Set the minHeight to 100vh to stick the footer to the bottom of the page.*/}
        {/* <Layout className="layout" style={{ minHeight: "100vh", display: 'flex' }}> */}
          {/* <Row style={{ display: 'flex', padding: '100px 0px 0px 0px', justifyContent: 'center' }}>
            <img src={logo} alt="Lasermet Alternative Logo" style={{width:"320px"}} />
          </Row> */}
          {/* <Row style={{ display: 'flex', padding: '20px 0px 0px 0px', justifyContent: 'center' }}>
            <Typography.Text style={{ color: '#3a4e84', fontSize: '30px', fontWeight: '500' }}>RealTime Laser Safety Software</Typography.Text>
          </Row> */}
{/* 
          <Content style={{
            padding: '20px 50px',
            justifyContent: 'center',
            display: 'flex'
          }}> */}

          <Flex justify='center' align='center' style={{ width: "100%", minHeight: "100vh" }}>
            <Card
              // title={<Typography.Text style={{ fontWeight: '500' }}>Log In to the System</Typography.Text>}
              style={{
                width: 500,
                height: 500,
                fontWeight: '500',
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px"
              }}
              cover={
                <Flex justify='center' align='center' style={{ marginBottom: "25px", width: "100%", height: "12vh", padding: "20px 0px 0px 20px" }}>
                  <img
                    justify='center' align='center'
                    src={logo}
                    style={{ width: "90%", justifySelf: "center"}}
                  />
                </Flex>
              }
              bordered={false}>
              <Form
                name="Log In"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your username.',
                    },
                  ]}
                >
                  <Input size={"large"} prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Username" />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your password.',
                    },
                  ]}
                >
                  {/*Input.Password has the visibility toggle*/}
                  <Input.Password
                    size={"large"}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Password"
                  />
                </Form.Item>

                <Form.Item>

                  <Row>
                    <Col span={12}>
                      <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox style={{ fontSize: '16px' }}>Remember me</Checkbox>
                      </Form.Item>
                    </Col>
                    {/* <Col span={12}>
                      <a className="login-form-forgot" href="/"
                        style={{ fontSize: '16px', float: 'right' }}>
                        Forgot password?
                      </a>
                    </Col> */}
                  </Row>


                </Form.Item>

                <Form.Item>
                  <Button loading={isSubmitting} size={'large'} type={'primary'} htmlType="submit"
                    className="login-form-button" block>
                    Log In
                  </Button>
                </Form.Item>
                <Divider plain style={{ fontSize: '16px' }}>Don't have an account yet? Register <Link
                  to="/register">here</Link>!</Divider>
                <Divider plain style={{ fontSize: '16px' }}>Got a confirmation code? Activate <Link
                  to="/confirm">here</Link>!</Divider>

                {flashMessage != null ? (
                  <Alert
                    description={flashMessage.message}
                    type={flashMessage.type}
                    closable
                    onClose={handleCloseFlash}
                  />
                ) : (null)}

                {/*For JSON format display only*/}
                {/* <Form.Item noStyle shouldUpdate>
                  {() => (
                    <Typography>
                      <pre>{JSON.stringify(form.getFieldsValue(), null, 2)}</pre>
                    </Typography>
                  )}
                </Form.Item> */}
              </Form>
            </Card>
          {/* </Content> */}
          </Flex>

          {/*Footer Section*/}
          <Footer style={{
            textAlign: 'center',
            backgroundColor: '#1d1f8c',
            color: '#f5f5f5',
            fontSize: '16px'
          }}
          >
            Lasermet Philippines © {year}. Created by Lasermet Philippines.
          </Footer>
        {/* </Layout> */}
      </ConfigProvider>
    </>
  )
}

export default Login;


