import { Form, InputNumber, Radio, Select, Tooltip, Card } from 'antd';
import React, {useState} from 'react';
import { NavLink } from 'react-router-dom';
import Field from '../../../../components/Field';


export function DRRPFields({ form }) {
    const [customPlaceholder, setCustomPlaceholder] = useState('Must be less than 250 ms')
    const [customMin, setCustomMin] = useState(0.25)
    const decimalForm = ({ unit, num }) => {
        switch (unit) {
            case 'T': {
                return num * 1e12
            }
            case 'G': {
                return num * 1e9
            }
            case 'M': {
                return num * 1e6
            }
            case 'k': {
                return num * 1e3
            }
            case false: {
                return num
            }
            case 'm': {
                return num * 1e-3
            }
            case 'u': {
                return num * 1e-6
            }
            case 'n': {
                return num * 1e-9
            }
            case 'p': {
                return num * 1e-12
            }
            case 'f': {
                return num * 1e-15
            }
        }
    }
    const handleUnitChange = (e) => {
        switch (e) {
            case false: {
                setCustomPlaceholder(`Must be less than 0.25 s`)
                setCustomMin(0.25)
                break
            }
            case 'm': {
                setCustomPlaceholder(`Must be less than 250 ms`)
                setCustomMin(250)
                break
            }
            case 'u': {
                setCustomPlaceholder(`Must be less than 250,000 µs`)
                setCustomMin(250000)
                break
            }
            case 'n': {
                setCustomPlaceholder(`Must be less than 250,000,000 ns`)
                setCustomMin(250000000)
                break
            }
            case 'p': {
                setCustomPlaceholder(`Must be less than 250,000,000,000 ps`)
                setCustomMin(250000000000)
                break
            }
            case 'f': {
                setCustomPlaceholder(`Must be less than 250,000,000,000,000 fs`)
                setCustomMin(250000000000000)
                break
            }

        }
    }
    return <>
        <Card title="Repetitvely Pulsed Settings">
            {/* Wavelength */}
            <Field
                name={['diffuseReflectiveRP', 'wavelength']}
                denoms={['u', 'n']}
                unit='m'
                min='180'
                max='1000000'
                label='Wavelength'
                placeholder='Enter wavelength (180nm - 1,000,000nm)'
                toolTipText={'The operating wavelength of the laser beam.'} />

            {/* Pulse Duration */}
            <Field
                name={['diffuseReflectiveRP', 'time']}
                label='Exposure Duration'
                unit='s'
                denoms={['f', 'p', 'n', 'u', 'm', false]}
                placeholder={`Enter exposure duration`}
                min='0.001'
                toolTipText={`The total time of anticipated direct eye exposure to the laser radiation.`}/>

            {
                /* Beam Diameter */
            }
            <Field
                name={['diffuseReflectiveRP', 'beamDiameter']}
                label='Beam Diameter'
                unit='mm'
                placeholder={`Enter laser's beam diameter`}
                toolTipText={`The diameter of the source of the laser beam.`} />
            {
                /* Beam Divergence */
            }
            <Field
                name={['diffuseReflectiveRP', 'beamDivergence']}
                label='Beam Divergence'
                unit='rad'
                denoms={['m', false, 'degree']}
                placeholder={`Enter laser's beam divergence`}
                toolTipText={`The far field plane angle of the cone defined by the beam diameter.`} />

            <Field
                name={['diffuseReflectiveRP', 'distance']}
                label='Laser to Surface Distance'
                unit='m'
                denoms={['k', false, 'm', 'c', 'u', 'n']}
                placeholder={`Enter distance between laser and reflecting surface`}
                toolTipText={`The distance between the source of the laser and the reflecting surface.`} />
        </Card>
        <Card title="Laser Output">
             {/* Beam Output Unit */}
             <Form.Item
                name={['diffuseReflectiveRP', 'beamOutputUnit']}
                label='Beam output units'>
                <Radio.Group optionType='default'>
                    <Radio.Button value='energy'>Energy</Radio.Button>
                    <Radio.Button value='peakPower'>Peak Power</Radio.Button>
                    <Radio.Button value='averagePower'>Average Power</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(prev, curr) => prev.diffuseReflectiveRP.beamOutputUnit !== curr.diffuseReflectiveRP.beamOutputUnit}>
                {({ getFieldValue }) => {
                    let beamOutputUnit = getFieldValue(['diffuseReflectiveRP', 'beamOutputUnit']);
                    if (beamOutputUnit === 'energy') {
                        return (
                            <Field
                                name={['diffuseReflectiveRP', 'energy']}
                                label='Pulse Energy'
                                unit='J'
                                denoms={['u', 'm', false, 'k', 'M']}
                                placeholder={`Enter laser's output value`}
                                toolTipText={`The laser's output energy measured in Joules.`} />
                        );
                    }
                    else if (beamOutputUnit === 'peakPower') {
                        return (
                            <Field
                                name={['diffuseReflectiveRP', 'peakPower']}
                                unit='W'
                                denoms={['G', 'M', 'k', false, 'm', 'u', 'n', 'p']}
                                placeholder={`Enter laser's power output`}
                                label={'Laser Output Power'}
                                toolTipText={'The continuous power output of the laser beam.'} />
                        );
                    }
                    else if (beamOutputUnit === 'averagePower') {
                        return (
                            <Field
                                name={['diffuseReflectiveRP', 'averagePower']}
                                unit='W'
                                denoms={['G', 'M', 'k', false, 'm', 'u', 'n', 'p']}
                                placeholder={`Enter laser's Average Power output`}
                                label={'Laser Average Power'}
                                toolTipText={'The continuous power output of the laser beam.'} />
                        );
                    }
                }}
            </Form.Item>
            <Tooltip
                        mouseEnterDelay={0.5}
                        title={`The time it takes for a whole pulse of laser radiation to be delivered.`}
                        placement='right'
                        destroyTooltipOnHide
                    > 
                        <Form.Item
                            label='Pulse Duration'
                            name={['diffuseReflectiveRP', 'pulseDuration', 'num']}
                            rules={[
                                { required: true, message: 'Please specify value.' },
                            ]}
                        >
                            <InputNumber
                                placeholder={customPlaceholder}
                                // max={customMin}
                                style={{ width: '100%' }}
                                addonAfter={
                                    <>
                                        <Form.Item noStyle
                                            name={['diffuseReflectiveRP', 'pulseDuration', 'unit']}
                                            rules={[
                                                { required: true, message: 'Please specify unit.' },
                                                () => ({
                                                    validator(_, value) {
                                                        let pulseFrequency = form.getFieldsValue()?.diffuseReflectiveRP?.pulseFrequency
                                                        let pulseDuration = form.getFieldsValue()?.diffuseReflectiveRP?.pulseDuration
                                                        let ratio = decimalForm(pulseFrequency) * decimalForm(pulseDuration)
                                                        console.log(`pulse duration: `, pulseDuration)
                                                        console.log(`the ratio:`, ratio)
                                                        if (decimalForm(pulseDuration) >= 0.25) {
                                                            return Promise.reject(<>The pulse duration is greater than or equal to 0.25 s - This is a CW laser! <NavLink to='#' onClick={() => { form.setFieldValue(['laserOperation'], 'cw') }}>Click here</NavLink> to use the CW calculator instead.</>)
                                                        } else {
                                                            if (ratio < 1) {
                                                                return Promise.resolve()
                                                            }
                                                            else if (ratio === 1) {
                                                                return Promise.reject(<>This is a CW laser! <NavLink to='#' onClick={() => { form.setFieldValue(['laserOperation'], 'cw') }}>Click here</NavLink> to calculate CW instead.</>)
                                                            } else if (ratio > 1) {
                                                                return Promise.reject('This is an invalid setup! Pulse frequency * Pulse duration should not be more than 1!')
                                                            }
                                                        }

                                                    }
                                                })
                                            ]}>
                                            <Select
                                                onChange={handleUnitChange}
                                                style={{ width: 80 }}
                                                options={(() => {
                                                    let units = ['f', 'p', 'n', 'u', 'm', false]
                                                    return units.map((unit, index) => {
                                                        if (unit === false) {
                                                            return (
                                                                {
                                                                    label: 's',
                                                                    value: false
                                                                }
                                                            )
                                                        } else if (unit === 'u') {
                                                            return (
                                                                {
                                                                    label: 'µs',
                                                                    value: unit
                                                                }
                                                            )
                                                        } else {
                                                            return (
                                                                {
                                                                    label: unit + 's',
                                                                    value: unit
                                                                }
                                                            )
                                                        }
                                                    })
                                                })()}
                                            />
                                        </Form.Item>
                                    </>
                                } />
                        </Form.Item>
                   </Tooltip>

                    <Field
                        name={['diffuseReflectiveRP', 'pulseFrequency']}
                        label='Pulse Frequency'
                        unit='Hz'
                        denoms={['u', 'm', false, 'k', 'M']}
                        placeholder={`Enter pulse frequency`}
                        min='0.001'
                        toolTipText={`The rate at which laser pulses are delivered within a given time period.`}
                />
        </Card>
    </>;

    
}
