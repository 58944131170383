import { Card, Checkbox, Form, message, Modal } from 'antd'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'

const rtllSettings = { expires: 365 }

const SettingsModal = (props) => {
    const { showSettingsModal, setShowSettingsModal } = props
    const [initialValues, setInitialValues] = useState(JSON.parse(Cookies.get('RTLSS_SETTINGS')))
    const [form] = Form.useForm()

    useEffect(() => {
        setInitialValues(JSON.parse(Cookies.get('RTLSS_SETTINGS')))
    }, [showSettingsModal])



    return (
        <Modal
            destroyOnClose
            title={'Calculator Settings'}
            open={showSettingsModal}
            onCancel={() => {
                setShowSettingsModal(false)
                form.resetFields()
            }}
            okText='Apply Settings'
            onOk={() => {
                let keys = Object.keys(form.getFieldsValue())
                let changed = keys.some((thisKey) => {
                    console.log(thisKey)
                    console.log(form.getFieldValue(thisKey), initialValues[thisKey])
                    return form.getFieldValue(thisKey) !== initialValues[thisKey]
                })
                if (changed) {
                    Cookies.set('RTLSS_SETTINGS', JSON.stringify({ ...JSON.parse(Cookies.get('RTLSS_SETTINGS')), ...(form.getFieldsValue()) }), rtllSettings)
                    message.success('Settings saved to browser.')
                } else {
                    message.info('No changes on the settings were made.')
                    form.resetFields()
                }

                setShowSettingsModal(false)

            }}
        >
            <Form
                form={form}
                name='settingsForm'
                initialValues={initialValues}>
                <Form.Item
                    label='Hide Calculation Confirmation'
                    help='When checked, this setting will hide the confirmation window that appears when you try to submit the calculation. This was put in to try to combat unwanted calculations.'
                    name={'hide_confirmation'}
                    valuePropName='checked'>
                    <Checkbox />
                </Form.Item>
            </Form>

        </Modal>
    )
}

export default SettingsModal